<template>
    <list-page
        page-title="Leases"
        page-icon="mdi-file-document-outline"
        collection-name="lease"
        :items-per-page="20"
        add-dialog-title="Add Lease"
        is-filter
    />
</template>

<script>
import ListPage from '../../../components/pages/List';

export default {
    name: 'BankAccountList',
    components: { ListPage },
};
</script>

<style scoped></style>
